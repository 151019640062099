<template>
  <div class="manage-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item>
          <i class="icon-home"></i>持续体糖监测管理
        </Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="member-search">
        <h2 class="member-search-title border-bottom">
          <span class="member-search-text">按条件搜索</span>
        </h2>
        <div class="search-form" @keyup.enter="handleSearch()">
          <Form :label-width="110" :model="searchData">
            <Row>
              <Col span="5">
                <Form-item label="监测开始日期：">
                  <Select>
                    <Option value="昨日">昨日</Option>
                    <Option value="最近30天">最近 30 天</Option>
                    <Option value="本月">本月</Option>
                    <Option value="上月">上月</Option>
                    <Option value="本周">本周</Option>
                    <Option value="上周">上周</Option>
                  </Select>
                </Form-item>
              </Col>
              <Col span="1"></Col>
              <Col span="5">
                <Form-item label="最近促进日期：">
                  <Select>
                    <Option value="昨日">昨日</Option>
                    <Option value="最近30天">最近 30 天</Option>
                    <Option value="本月">本月</Option>
                    <Option value="上月">上月</Option>
                    <Option value="本周">本周</Option>
                    <Option value="上周">上周</Option>
                  </Select>
                </Form-item>
              </Col>
            </Row>
            <Row>
              <Col span="5">
                <Form-item label="发射器电量：">
                  <Select v-model="searchData.power">
                    <Option value="2.9">&lt;2.9</Option>
                    <Option value="2.8">&lt;2.8</Option>
                  </Select>
                </Form-item>
              </Col>
              <Col span="1"></Col>
              <Col span="5">
                <Form-item label="体糖范围：">
                  <Select v-model="searchData.area_type">
                    <Option value="1">(0,4.4)</Option>
                    <Option value="2">[4.4,6.1]</Option>
                    <Option value="3">(6.1,7.8)</Option>
                    <Option value="4">[7.8,~)</Option>
                  </Select>
                </Form-item>
              </Col>
              <Col span="1"></Col>
              <Col span="12">
                <Form-item label="体糖区间：">
                  <InputNumber v-model="searchData.area_min" placeholder="最小值" style="width: 85px;"></InputNumber>
                  <span style="margin: 0 5px;">至</span>
                  <InputNumber v-model="searchData.area_max" placeholder="最大值" style="width: 85px;"></InputNumber>
                </Form-item>
              </Col>
            </Row>
            <Row>
              <Col span="5">
                <Form-item label="发射器码查询：">
                  <Input v-model="searchData.qrCode" placeholder="请输入..."></Input>
                </Form-item>
              </Col>
              <Col span="1"></Col>
              <Col span="5">
                <Form-item label="监测天数：">
                  <Select v-model="searchData.monitor_days">
                    <Option v-for="i in 16" :key="i" :value="i">{{ i }}</Option>
                  </Select>
                </Form-item>
              </Col>
              <Col span="1"></Col>
              <Col span="5">
                <Form-item label="监测状态：">
                  <Select v-model="searchData.monitor_status">
                    <Option value="">全部</Option>
                    <Option value="监测中">监测中</Option>
                    <Option value="监测结束">监测结束</Option>
                    <Option value="黄色监测异常">黄色监测异常</Option>
                    <Option value="红色监测异常">红色监测异常</Option>
                    <Option value="待参比">待参比</Option>
                    <Option value="监测超时">监测超时</Option>
                    <Option value="待结束">待结束</Option>
                  </Select>
                </Form-item>
              </Col>
              <Col span="1"></Col>
              <Col span="5">
                <Form-item label="手机号：">
                  <Input v-model="searchData.mobile" placeholder="请输入用户手机号"></Input>
                </Form-item>
              </Col>
            </Row>
            <Row v-if="!branchShow">
              <Col span="5">
                <Form-item label="省份：">
                  <Select
                    clearable
                    v-model="searchData.pid"
                    placeholder="请选择"
                    @on-change="changePro"
                  >
                    <Option :value="v.id" v-for="v in proList" :key="v.id">
                      {{
                        v.name
                      }}
                    </Option>
                  </Select>
                </Form-item>
              </Col>
              <Col span="1"></Col>
              <Col span="5">
                <Form-item label="城市：">
                  <Select
                    clearable
                    v-model="searchData.cid"
                    placeholder="请选择"
                    @on-change="changeCity"
                    @click.native="changeBossCity"
                  >
                    <Option :value="v.id" v-for="v in cityList" :key="v.id">
                      {{
                        v.name
                      }}
                    </Option>
                  </Select>
                </Form-item>
              </Col>
              <Col span="1"></Col>
              <Col span="5">
                <Form-item label="门店：">
                  <Select
                    clearable
                    v-model="searchData.sid"
                    placeholder="请选择"
                    @on-change="changeStore"
                    @click.native="changeBossBranch"
                  >
                    <Option :value="v.id" v-for="v in storeList" :key="v.id">
                      {{
                        v.name
                      }}
                    </Option>
                  </Select>
                </Form-item>
              </Col>
              <Col span="1"></Col>
              <Col span="5">
                <Form-item label="用户：">
                  <Select
                    clearable
                    filterable
                    placeholder="请选择"
                    v-model="searchData.member_id"
                    :remote-method="remoteMethod"
                    :loading="remoteLoading"
                  >
                    <Option
                      v-for="item in userList"
                      :value="item.member_id"
                      :key="item.member_id"
                    >{{ item.name }}</Option>
                  </Select>
                </Form-item>
              </Col>
            </Row>
            <Row v-else>
              <Col span="5">
                <Form-item label="省份：">
                  <Input disabled v-model="proName"></Input>
                </Form-item>
              </Col>
              <Col span="5">
                <Form-item label="城市：">
                  <Input disabled v-model="cityName"></Input>
                </Form-item>
              </Col>
              <Col span="5">
                <Form-item label="门店：">
                  <Input disabled v-model="shopName"></Input>
                </Form-item>
              </Col>
              <Col span="5">
                <Form-item label="用户：">
                  <Select
                    clearable
                    placeholder="请选择"
                    v-model="searchData.member_id"
                    @click.native="handleChangeStaff"
                  >
                    <Option
                      v-for="item in userList"
                      :value="item.member_id"
                      :key="item.member_id"
                    >{{ item.name }}</Option>
                  </Select>
                </Form-item>
              </Col>
            </Row>
          </Form>
          <div class="search-footer">
            <Button type="warning" @click="handleSearch">&nbsp;&nbsp;搜索&nbsp;&nbsp;</Button>
          </div>
        </div>
      </div>
      <div class="content-box">
        <h2 class="content-title border-bottom">持续体糖监测管理列表</h2>
        <div class="content-body">
          <Table :columns="columns" :data="resultData"></Table>
          <Row>
            <Col span="12">
              <div class="table-oprate"></div>
            </Col>
            <Col span="12">
              <span class="records">共{{ pageTotal }}条记录</span>
            </Col>
          </Row>
          <div class="page-nations-wrapper">
            <div class="page-nations">
              <Page
                :total="pageTotal"
                :current="searchData.page"
                :page-size="searchData.size"
                @on-change="handleChangePage"
              ></Page>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import memberService from '@/services/memberService';
import servicesService from '@/services/servicesService';
import dayjs from 'dayjs';

export default {
	components: {
		'v-title': vTitle,
	},
	data() {
		return {
			searchData: {
				page: 1,
				size: 20,
				mobile: '',
				qrCode: '',
				monitor_status: '',
				monitor_days: '',
				power: '',
				area_type: '',
				area_min: null,
				area_max: null,
			},
			pageTotal: 0,
			proList: [],
			cityList: [],
			storeList: [],
			userList: [],
			branchShow: false,
			branch_id: [],
			power: [],
			proName: '',
			cityName: '',
			shopName: '',
			columns: [
				{
					title: '用户',
					key: 'yh',
					align: 'center',
					render: (h, params) => {
						return h(
							'a',
							{
								style: {
									color: '#fdad00',
								},
								attrs: {
									target: '_blank',
									href: '/#/base/member/detail/' + params.row.member_id + '/0',
								},
							},
							params.row.yh,
						);
					},
				},
				{ title: '会员类型', key: 'hylx', align: 'center' },
				{ title: '所属门店', key: 'ssmd', align: 'center' },
				{ title: '手机号', key: 'sjh', align: 'center' },
				{
					title: '监测开始',
					key: 'jckssj',
					align: 'center',
					render: (h, params) => {
						return h('span', dayjs(params.row.jckssj).format('YYYY-MM-DD HH:mm'));
					},
				},
				{
					title: '最新数据',
					key: 'lastDataBagTime',
					align: 'center',
					render: (h, params) => {
						return h('span', dayjs(params.row.lastDataBagTime).format('YYYY-MM-DD HH:mm'));
					},
				},
				{
					title: '监测状态',
					key: 'jczt',
					align: 'center',
					render: (h, params) =>
						h(
							'span',
							{
								style: {
									color: params.row.jczt_color,
								},
							},
							params.row.jczt,
						),
				},
				{ title: '异常状态', key: 'abnormal_dictText', align: 'center' },
				{
					title: 'DAY',
					key: 'pdsc',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color: '#5196c2',
								},
							},
							params.row.pdsc,
						);
					},
				},
				// { title: '数据时长', key: 'jcts', align: 'center' },
				{ title: '发射器电量', key: 'lastPower', align: 'center' },
				{ title: '参比次数', key: 'jzcs', align: 'center' },
				{ title: '进店次数', key: 'jdcs', align: 'center' },
				{ title: '饮食次数', key: 'yscs', align: 'center' },
				{ title: '咨询内容', key: 'zxnr', align: 'center' },
				{ title: '咨询日期', key: 'zjzxrq', align: 'center' },
				{
					title: '报告',
					align: 'center',
					render: (h, params) => {
						return h(
							'Button',
							{
								attrs: {
									type: 'primary',
									size: 'small',
								},
								on: {
									click: () => {
										const data = params.row;
										window.open(
											`/#/base/blood-sugar-manage/report/${data.member_id}/${data.snapshotId}`,
										);
									},
								},
							},
							'生成报告',
						);
					},
				},
				{
					title: '操作',
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h('a', {
								class: 'icon_eye',
								style: { marginRight: '5px' },
								on: {
									click: () => {
										const data = params.row;
										window.open(`/#/base/blood-sugar-manage/${data.member_id}/${data.snapshotId}`);
									},
								},
							}),
							params.row.qzsj === 1 &&
								h('Icon', {
									attrs: {
										type: 'md-checkmark-circle-outline',
										size: 18,
										color: '#1aaa04',
									},
									style: {
										verticalAlign: 'middle',
									},
									on: {
										click: () => {
											servicesService
												.endMQMonitor({ snapshotId: params.row.snapshotId })
												.then(() => {
													this.$Message.success('操作成功');
													this.handleSearch();
												});
										},
									},
								}),
						]);
					},
				},
			],
			resultData: [],
			remoteLoading: false,
		};
	},
	mounted() {
		this.initData();
		this.handleSearch();
	},
	methods: {
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			if (this.searchData.sid) {
				const params = { sid: this.searchData.sid };
				servicesService.getMQReportUsers(params).then((data) => {
					this.userList = data;
				});
			} else {
				this.userList = [];
			}
		},
		remoteMethod(query) {
			if (query !== '') {
				this.remoteLoading = true;
				const params = { keyword: query };
				servicesService.getMQReportUsers(params).then((data) => {
					this.userList = data;
					this.remoteLoading = false;
				});
			} else {
				this.userList = [];
			}
		},
		// 点击门店先选择城市
		changeBossBranch() {
			if (!this.searchData.cid) {
				this.$Message.warning('请先选择城市');
			}
		},
		// 点击城市先选择省份
		changeBossCity() {
			if (!this.searchData.pid) {
				this.$Message.warning('请先选择省份');
			}
		},
		initData() {
			let list = JSON.parse(localStorage.getItem('userInfo'));
			memberService.getBase().then((data) => {
				this.proList = data.branch.pro;
				this.city = data.branch.city;
				this.store = data.branch.store;
				this.power = list.user_info.power;
				this.memberTypeData = data.member_type;
				if (this.power.indexOf('服务管理--查看所属店面会员信息权限') === -1) {
					this.branchShow = false;
				} else {
					if (
						this.power.indexOf('服务管理--查看全国会员信息权限') === -1 &&
						this.power.indexOf('服务管理--查看所属区域会员信息权限') === -1
					) {
						this.branchShow = true;
						this.branch_id = list.user_info.branch;
						this.searchData.sid = this.branch_id[2];
						this.searchData.cid = this.branch_id[1];
						this.searchData.pid = this.branch_id[0];
						this.proName = list.user_info.pro_name;
						this.cityName = list.user_info.city_name;
						this.shopName = list.user_info.branch_name;
						this.changeStore();
					}
				}
			});
		},
		handleSearch() {
			if (this.searchData.area_min > this.searchData.area_max) {
				this.$Message.warning('区间最小值不能大于最大值');
				return;
			}
			servicesService.MQBloodSugarList(this.searchData).then((data) => {
				this.resultData = data.list;
				this.pageTotal = data.row_size;
			});
		},
		handleChangePage(page) {
			this.searchData.page = page;
			this.handleSearch();
		},
	},
};
</script>

<style lang="less" scoped>
.member-search {
  background-color: #fff;
  margin-bottom: 20px;
  :deep(.ivu-input-number-input) {
    vertical-align: baseline;
  }
}
.search-form {
  padding: 20px 15px;
}
.search-footer {
  text-align: center;
}
</style>
